<template>
  <span>
    <v-btn
      small
      class="me-2"
      @click="paginate"
      :disabled="disabledExecute"
      color="primary"
    >
      <v-icon left>mdi-filter-check-outline</v-icon>
      {{ $t("quotation.execute") }}
    </v-btn>
    <v-btn outlined class="me-2" small @click="reset" color="primary">
      <v-icon left>mdi-autorenew</v-icon>
      {{ $t("quotation.reset") }}
    </v-btn>
  </span>
</template>

<script>
export default {
  name: "ExecuteAndRestCommon",
  props: ["valid", "type"],
  computed: {
    disabledExecute() {
      return this.type ? false : this.valid ? !this.valid : false;
    },
  },
  methods: {
    reset() {
      this.$emit("reset");
    },
    paginate() {
      this.$emit("paginate", false);
    },
  },
};
</script>
