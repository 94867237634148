<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <ListSkeleton v-if="listSkeleton" />
          <v-card-text class="text-h5 pa-0" v-else>
            <v-data-table
              :height="bankReportList && bankReportList.length > 0 && showHeaders.length > 0 ? '360' : ''"
              :style="{maxHeight: !bankReportList ? '340px' : ''}"
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="showHeaders"
              :items="bankReportList"
              style="word-break: break-word"
              :search="search"
              item-class="py-4"
              fixed-header
              :loading="isProgressCircular"
              :items-per-page="limitData"
              :page.sync="page"
              @pagination="newPagination"
              @dblclick:row="doubleClick"
              @page-count="pageCount = $event"
              must-sort
            >
              <template v-slot:loading>
                <v-overlay>
                  <v-icon size="64" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
                </v-overlay>
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <span :ref="`row-${item.id}`">{{ item.id }}</span>
              </template>
              <template v-slot:top>
                <v-card-title class="py-1 primary--text">
                  <v-icon color="primary">mdi-filter-cog-outline</v-icon>
                  <h6 class="ms-2 font-weight-regular">
                    {{ $t("bank.filters") }}
                  </h6>
                  <v-spacer />
                  <HtmlToPdf class="mx-1 mb-1" :disabled="bankReportList.length" :model="bankReportList" type="BankReportPDF"/>
                  <v-btn
                    small
                    class="float-end"
                    color="primary"
                    :disabled="!bankReportList.length"
                    outlined
                    @click="downloadExcelFile"
                  >
                    <v-icon left>mdi-microsoft-excel</v-icon>
                    {{ $t("bank.excel") }}
                  </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" class="text-center mt-3">
                      <ExecuteAndRestCommon @reset="reset" @paginate="paginate(false)" type="Bank" />
                      <v-dialog
                        dense
                        v-model="dialog"
                        scrollable
                        max-width="500px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            color="primary"
                            class="float-end"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon left> mdi-view-comfy </v-icon>
                            {{ $t("bank.fieldView") }}
                          </v-btn>
                        </template>
                        <v-card>
                          <v-row class="align-center ma-0">
                            <v-col>
                              <v-card-title class="primary--text">
                                <v-icon left color="primary">mdi-land-fields</v-icon>
                                  <h6 class="font-weight-regular">
                                    {{ $t("bank.selectFields") }}
                                  </h6>
                              </v-card-title>
                            </v-col>
                            <v-col>
                              <v-btn x-small color="primary" class="float-end mx-3" @click="updateHeader">{{ $t('bank.update') }}</v-btn>
                            </v-col>
                          </v-row>
                          <v-divider />
                          <v-card-text style="height: 300px;">
                            <v-select
                              dense
                              class="mt-2"
                              hide-details="auto"
                              v-model="selectedHeaders"
                              :items="headers"
                              item-text="text"
                              item-value="id"
                              return-object
                              clearable
                              multiple
                              outlined
                              :menu-props="{ bottom: true, offsetY: true, maxHeight: 200, overflowY: true }"
                              :label="$t('bank.selectFields')"
                            >
                              <template v-slot:prepend-item>
                                <v-list-item
                                  @mousedown.prevent
                                  @click="toggle"
                                >
                                  <v-list-item-action>
                                    <v-checkbox dense v-model="selectFields" />
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{$t('bank.selectAll')}}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2" />
                              </template>
                            </v-select>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-title class="py-1 primary--text">
                  <v-row dense no-gutters>
                    <v-col cols="9" class="d-flex">
                      <v-icon color="primary">mdi-view-list-outline</v-icon>
                      <h6 class="ms-2 align-self-center font-weight-regular">
                        {{ $t("bank.debitReport") }}
                      </h6>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        ref="search"
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        :autofocus="type ? true : false"
                        v-model="search"
                        @input="buildUrl"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider />
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto" cols="12">
                    <span class="pl-3 pt-2">{{ $t('common.numberOfTotalRows') }} {{ bankReportList && bankReportList.length && showHeaders.length > 0 ? numberOfRows : 0 }}</span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination :total-visible="5" v-model="page" :length="pageCount"></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:no-data>
                <v-card-text class="text-caption text-center">{{ $t("common.noDataFound") }}</v-card-text>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import exportFromJSON from 'export-from-json';
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import ExecuteAndRestCommon from "@/components/ExecuteAndRestCommon";

export default {
  name: "BankReport",
  props: ['type'],
  components: {
    ListSkeleton,
    HtmlToPdf,
    ExecuteAndRestCommon
  },
  data() {
    return {
      sortByValue: null,
      sortDescValue: null,
      bankLimit: 10,
      listSkeleton: false,
      dialog: false,
      selectedHeaders: [],
      selectFields: false,
      search: this.$route.query.search || "",
      limitData: this.limit,
      limit: this.pageChange?.limit ? Number(this.pageChange?.limit) : 10,
      page: this.pageChange?.page ? Number(this.pageChange?.page) : 1,
      pagination: null,
      numberOfRows: "",
      isProgressCircular: false,
    };
  },
  computed: {
    params() {
      return {
        query: this.search,
      }
    },
    selectAllHeaders() {
      return this.selectedHeaders.length === this.headers.length;
    },
    ...mapGetters({
      bankReportList: "bank/bankReportList",
      locale: "locale",
      showHeaders: "bankReport/showHeaders",
      accessRight: 'accessRight',
      pageChange: "bankReport/pageChange",
      rowIndex: "bank/rowIndex",
    }),
    headers() {
      return [
        { text: this.$t("bank.id"), value: "id", width: "130px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("bank.field.name"), value: "name", width: "120px", class: "px-2", sortable: true },
        { text: this.$t("bank.field.email"), value: "email", width: "160px", class: "px-2", sortable: true },
        { text: this.$t("bank.field.branchNo"), value: "branch_no", width: "120px", class: "px-2", sortable: true },
        { text: this.$t("bank.field.branchName"), value: "branch_name", width: "120px", class: "px-2", sortable: true },
        { text: this.$t("bank.field.accountNo"), value: "account_no", width: "120px", class: "px-2", sortable: true },
        { text: this.$t("bank.field.accountingNo"), value: "accounting_no", width: "120px", class: "px-2", sortable: true },
        { text: this.$t("bank.field.contactName"), value: "contact_name", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("bank.field.address"), value: "address", width: "160px", class: "px-2", sortable: true },
        { text: this.$t("bank.field.town"), value: "town", width: "120px", class: "px-2", sortable: true },
        { text: this.$t("bank.field.postalCode"), value: "postal_code", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("bank.field.tel1"), value: "phone_1", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("bank.field.tel2"), value: "phone_2", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("bank.field.tel3"), value: "phone_3", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("bank.field.site"), value: "site", width: "120px", class: "px-2", sortable: true },
        { text: this.$t("bank.field.fax"), value: "fax", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("bank.field.createdAt"), value: "created_at", width: "180px", class: "px-2", sortable: true },
        { text: this.$t("bank.field.updatedAt"), value: "updated_at", width: "180px", class: "px-2", sortable: true },
      ];
    },
  },
  created() {
    if (this.showHeaders.length == 0) {
      this.$store.commit('bankReport/SHOW_HEADERS', this.headers)
    }
    this.page = this.pageChange.page;
    this.limit = this.pageChange.limit;
    this.limitData = this.limit;
  },
  beforeDestroy() {
    this.$store.commit("bankReport/SHOW_PAGE_CHANGE", {page: this.pagination.page, limit: this.pagination.itemsPerPage})
  },
  destroyed() {
    if (!this.$route.params.bank_id) {
      this.$store.commit('bank/SET_BANK_REPORT', [])
    }
  },
  watch: {
    pagination: {
      handler(newVal) {
        if (this.pagination) {
          this.limit = newVal.itemsPerPage ? newVal.itemsPerPage : 10
        }
      },
      deep: true
    },
    type: {
      handler() {
        this.search = '';
      }
    },
    '$route.query': {
      handler(newVal, oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length) 
        this.search = this.$route.query.search || ""
      }
    },
    selectedHeaders() {
      if (this.selectedHeaders.length === this.headers.length) {
        this.selectFields = true;
      } else {
        this.selectFields = false;
      }
    },
    locale() {
      this.updateHeader();
    },
  },
  async mounted() {
    this.listSkeleton = true;
    this.selectedHeaders = this.showHeaders;
    this.page = this.pageChange.page;
    this.limit = this.pageChange.limit;
    this.limitData = this.limit;
    this.$store.dispatch("bank/GetBank");
    this.paginate();
    let rowIndex = this.$store.state.bank.rowIndex;
    if (rowIndex && this.bankReportList.length > 0) {
      this.$refs[`row-${rowIndex}`].scrollIntoView({ block: "center" })
    }
    this.listSkeleton = false;
  },
  methods: {
    doubleClick(event, {item}) {
      const path = 'bank/editBank/' + item.id;
      this.accessRight.includes('edit') || this.accessRight.includes('show') ? window.open(path, '_blank') : '';
      this.$store.commit('bank/SET_ROWINDEX', item.id);
    },
    newPagination(val) {
      this.pagination = val
      this.numberOfRows = val.itemsLength
    },
    updateHeader() {
      this.$store.commit('bankReport/SHOW_HEADERS', this.headers.filter((s) => this.selectedHeaders.some((d) => d.value === s.value)))
      this.selectedHeaders = this.showHeaders;
      this.dialog = false;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllHeaders) {
          this.selectedHeaders = [];
        } else {
          this.selectedHeaders = this.headers.slice();
        }
      });
    },
    reset() {
      this.search = null;
      this.$store.commit('bank/SET_BANK_REPORT', [])
      this.page = 1;
      this.limit = 10;
      this.limitData = this.limit;
    },
    downloadExcelFile() {
      let self = this;
      let data = self.bankReportList.map((o) => Object.fromEntries(self.selectedHeaders.map(k => [k.text, k.value.includes('.')?
      o[`${k.value.split('.')[0]}`] && Object.keys(o[`${k.value.split('.')[0]}`]).length > 0 ? o[`${k.value.split('.')[0]}`][`${k.value.split('.')[1]}`] : o[`${k.value.split('.')[0]}`]:o[`${k.value}`]])))
      data.forEach((el)=> {
        for (let x in el) {
          if (!el[x]) {
            el[x] = ''
          }}
      })
      exportFromJSON({data: JSON.parse(JSON.stringify(data)), fileName: "bankReport", exportType: exportFromJSON.types.xls});
    },
    numberWithCommas(x) {
      return x ? parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (0).toFixed(2);
    },
    buildUrl(val) {
      let obj = {};
      if (typeof val === "string" || typeof val === "object") {
        // this.page = 1;
        // obj.page = 1;
        // this.params.page = 1;
      }
      obj.search = this.search;
      obj.order_by = this.sortByValue;
      obj.sortDec = this.sortDescValue;
      obj = Object.entries(obj).reduce((acc, [key, val]) => {
        if (key === "order_by" && val === null) return acc;
        else if (key === "sortDec" && val === null) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$store.commit("bank/SET_BANK_REPORT_QUERY", obj);
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.bankLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async paginate() {
      this.isProgressCircular = true;
      await this.$store.dispatch("bank/GetBankReport").then(() => {
        this.isProgressCircular = false;
        this.$refs.search.focus();
      });
    },
  },
};
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
</style>